<template>
  <div class="home">
    <v-layout row wrap class="d-flex justify-center">
      <v-flex xs12 md12 lg12 class="mb-5">
        <h1 class="f-alfa primary--text">
          <router-link to="/">Dashboard</router-link> / Accounts
        </h1>
      </v-flex>
      <v-flex xs12 class="mb-5">
        <v-layout row wrap class="d-flex justify-space-between align-center">
          <v-flex xs8 md6 lg4>
            <v-text-field
              single-line
              outlined
              label="Type to search.."
              prepend-inner-icon="mdi-magnify"
              v-model="searchText"
            ></v-text-field>
          </v-flex>
          <v-flex xs4 md4 lg4 class="d-flex justify-end">
            <v-btn class="primary black--text" x-large @click="addUser"
              >Add Account</v-btn
            >
          </v-flex>
        </v-layout>
      </v-flex>
      <v-flex xs12>
        <v-simple-table>
          <template v-slot:default>
            <thead>
              <tr>
                <th class="text-left uppercase primary--text">Name</th>
                <th class="text-left uppercase primary--text">Phone</th>
                <th class="text-left uppercase primary--text">Email</th>
                <th class="text-left uppercase primary--text">Role</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="item in filteredAdmins" :key="item._id">
                <td>{{ item.name }}</td>
                <td>{{ item.phone }}</td>
                <td>{{ item.email }}</td>
                <td>{{ item.role }}</td>
                <td>
                  <v-btn
                    small
                    class="primary black--text"
                    @click="viewAccount(item._id)"
                    ><v-icon>mdi-magnify</v-icon></v-btn
                  >
                </td>
              </tr>
            </tbody>
          </template>
        </v-simple-table>
      </v-flex>
    </v-layout>
  </div>
</template>

<script>
import Axios from "axios";
import { BASE_URL } from "@/config";
export default {
  data() {
    return {
      admins: [],
      searchText: "",
    };
  },
  mounted() {
    this.fetchAdmins();
  },
  computed: {
    filteredAdmins() {
      const query = this.searchText.toLowerCase();
      return this.admins.filter((item) => {
        if (item.role.toLowerCase() === "agent") {
          return false;
        }
        if (query.length >= 3) {
          return JSON.stringify(item).toLowerCase().includes(query);
        }
        return true;
      });
    },
  },
  methods: {
    async fetchAdmins() {
      let url = BASE_URL + "/admin";
      let { data } = await Axios.get(url);
      this.admins = data;
    },
    viewAccount(id) {
      this.$router.push({ name: "ViewAccount", params: { id: id } });
    },
    addUser() {
      this.$router.push("/add-account");
    },
  },
};
</script>


